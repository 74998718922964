import {
	Box,
	FormControlProps,
	Select,
	useStyleConfig,
} from "@chakra-ui/react";
import React, { ReactElement, ReactNode } from "react";
import { Path, useFormContext } from "react-hook-form";
import { FormErrorMessageI18n } from "../FormErrorMessageI18n/FormErrorMessageI18n";
import { FormControl, FormLabel } from "../forms";
import type { TypeOf, ZodType } from "zod";

type SelectProps<FormSchema extends ZodType> = {
	name: Path<TypeOf<FormSchema>>;
	label: ReactNode;
	children: ReactElement | Array<ReactElement>;
	placeholder?: string;
	isDisabled?: boolean;
	defaultValue?: string;
	isInvalid: boolean;
	value?: string;
	onChange?: (event?: React.ChangeEvent<HTMLSelectElement>) => void;
	icon?: ReactElement;
} & FormControlProps;

export const FormSelect = <FormSchema extends ZodType>({
	children,
	name,
	label,
	placeholder,
	isDisabled,
	isInvalid,
	variant,
	value,
	onChange,
	icon,
	...props
}: SelectProps<FormSchema>) => {
	const {
		register,
		formState: { errors },
	} = useFormContext<TypeOf<FormSchema>>();

	const styles = useStyleConfig("FormSelect", { variant });

	const { onChange: nativeOnChange, ...rest } = register(name);

	return (
		<FormControl isInvalid={isInvalid} {...props}>
			<Box sx={styles}>
				<FormLabel>{label}</FormLabel>
				<Select
					placeholder={placeholder}
					isDisabled={isDisabled}
					value={value}
					onChange={(event) => {
						onChange?.(event);
						nativeOnChange(event);
					}}
					icon={icon}
					{...rest}
				>
					{children}
				</Select>
			</Box>
			<FormErrorMessageI18n message={errors[name]?.message?.toString()} />
		</FormControl>
	);
};

// 🔬 TBD: Please evaluate
